import * as React from "react";
import { Box } from "@chakra-ui/react";
import AskQuestionBox from "../../components/AskQuestionBox";

const AskQuestionPage: React.FC = () => {
    return (
        <Box>
            <AskQuestionBox />
        </Box>
    );
};

export default AskQuestionPage;