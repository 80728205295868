import React, { useState, useEffect } from 'react';
import { Box, Avatar, Text, Button, Stack, Spinner, Heading, useColorModeValue, Flex } from '@chakra-ui/react';
import { User } from '../../models/Models';
import { UsersAPI } from '../../APIs/UsersAPI';
import {ObjectId} from "bson";

interface UsersListProps {
    onSelectUser: (userId: ObjectId) => void;
}

const UsersList: React.FC<UsersListProps> = ({ onSelectUser }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const textColor = useColorModeValue('light.text', 'dark.text');
    const bgColor = useColorModeValue('light.background', 'dark.background');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const allUsers = await UsersAPI.get_users();
                setUsers(allUsers);
            } catch (error) {
                console.error('Failed to fetch users:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers().then();
    }, []);

    if (loading) {
        return (
            <Box textAlign="center" mt={8}>
                <Spinner size="xl" />
            </Box>
        );
    }

    return (
        <Box p={4} bg={bgColor} borderRadius="md" boxShadow="md">
            <Heading size="md" mb={4} color={textColor}>
                Users List
            </Heading>
            <Stack spacing={4}>
                {users.map((user) => (
                    <Flex key={user._id.toHexString()} align="center" justify="space-between" p={2} bg={bgColor} borderRadius="md" boxShadow="sm">
                        <Flex align="center">
                            <Avatar size="md" name={user.full_name} src={user.photo_url} mr={4} />
                            <Text color={textColor}>{user.full_name}</Text>
                        </Flex>
                        <Button size="sm" colorScheme="blue" onClick={() => onSelectUser(user._id)}>
                            View Profile
                        </Button>
                    </Flex>
                ))}
            </Stack>
        </Box>
    );
};

export default UsersList;