// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB16FIMkct7hT52rq6QDyEC2mnz2QC6kJA",
    authDomain: "futuremed-mentor.firebaseapp.com",
    projectId: "futuremed-mentor",
    storageBucket: "futuremed-mentor.appspot.com",
    messagingSenderId: "994875007303",
    appId: "1:994875007303:web:a156e89af7ae63dabd6c62",
    measurementId: "G-3Z35T29L8P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth };