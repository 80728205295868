import React from 'react';
import {
    Box,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    Stack,
    Link,
    useColorModeValue,
} from '@chakra-ui/react';

const PrivacyPolicy: React.FC = () => {
    const textColor = useColorModeValue('text', 'text');
    const headingColor = useColorModeValue('primary.500', 'primary.500');

    return (
        <Box p={6} color={textColor}>
            <Heading as="h1" mb={4} color={headingColor}>
                Privacy Policy
            </Heading>
            <Text mb={4}>Last Updated: 01/09/2024</Text>

            <Stack spacing={4}>
                <Text>
                    This Privacy Policy describes Our policies and procedures on the collection, use, and
                    disclosure of Your information when You use the Service and tells You about Your privacy
                    rights and how the law protects You. We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection and use of information in
                    accordance with this Privacy Policy.
                </Text>

                <Heading as="h2" size="md" mt={6} mb={2} color={headingColor}>
                    Interpretation and Definitions
                </Heading>

                <Heading as="h3" size="sm" mt={4} mb={2}>
                    Interpretation
                </Heading>
                <Text>
                    The words of which the initial letter is capitalized have meanings defined under the
                    following conditions. The following definitions shall have the same meaning regardless of
                    whether they appear in singular or in plural.
                </Text>

                <Heading as="h3" size="sm" mt={4} mb={2}>
                    Definitions
                </Heading>
                <Text>For the purposes of this Privacy Policy:</Text>
                <UnorderedList pl={6}>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Account
                        </Text>{' '}
                        means a unique account created for You to access our Service or parts of our Service.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Company
                        </Text>{' '}
                        (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
                        CasPrep.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Cookies
                        </Text>{' '}
                        are small files that are placed on Your computer, mobile device or any other device by a
                        website, containing the details of Your browsing history on that website among its many
                        uses.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Country
                        </Text>{' '}
                        refers to Canada and the United States.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Device
                        </Text>{' '}
                        means any device that can access the Service such as a computer, a cellphone, or a
                        digital tablet.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Personal Data
                        </Text>{' '}
                        is any information that relates to an identified or identifiable individual.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Service
                        </Text>{' '}
                        refers to the Website.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Service Provider
                        </Text>{' '}
                        means any natural or legal person who processes the data on behalf of the Company. It
                        refers to third-party companies or individuals employed by the Company to facilitate the
                        Service, to provide the Service on behalf of the Company, to perform services related to
                        the Service or to assist the Company in analyzing how the Service is used.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Third-party Social Media Service
                        </Text>{' '}
                        refers to any website or any social network website through which a User can log in or
                        create an account to use the Service.
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Usage Data
                        </Text>{' '}
                        refers to data collected automatically, either generated by the use of the Service or
                        from the Service infrastructure itself (for example, the duration of a page visit).
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Website
                        </Text>{' '}
                        refers to CasPrep, accessible from{' '}
                        <Link href="http://www.casprep.net" isExternal color={headingColor}>
                            www.casprep.net
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            You
                        </Text>{' '}
                        means the individual accessing or using the Service, or the company, or other legal
                        entity on behalf of which such individual is accessing or using the Service, as
                        applicable.
                    </ListItem>
                </UnorderedList>

                <Heading as="h2" size="md" mt={6} mb={2} color={headingColor}>
                    Collecting and Using Your Personal Data
                </Heading>

                <Heading as="h3" size="sm" mt={4} mb={2}>
                    Types of Data Collected
                </Heading>

                <Heading as="h4" size="sm" mt={4} mb={2}>
                    Personal Data
                </Heading>
                <Text>
                    While using Our Service, We may ask You to provide Us with certain personally identifiable
                    information that can be used to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                </Text>
                <UnorderedList pl={6}>
                    <ListItem>Email address</ListItem>
                    <ListItem>First name and last name</ListItem>
                    <ListItem>Academic information</ListItem>
                    <ListItem>Usage Data</ListItem>
                </UnorderedList>

                <Heading as="h4" size="sm" mt={4} mb={2}>
                    Usage Data
                </Heading>
                <Text>
                    Usage Data is collected automatically when using the Service. Usage Data may include
                    information such as Your Device's Internet Protocol address (e.g., IP address), browser
                    type, browser version, the pages of our Service that You visit, the time and date of Your
                    visit, the time spent on those pages, unique device identifiers, and other diagnostic
                    data.
                </Text>
                <Text>
                    When You access the Service by or through a mobile device, We may collect certain
                    information automatically, including, but not limited to, the type of mobile device You
                    use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile
                    operating system, the type of mobile Internet browser You use, unique device identifiers,
                    and other diagnostic data.
                </Text>
                <Text>
                    We may also collect information that Your browser sends whenever You visit our Service or
                    when You access the Service by or through a mobile device.
                </Text>

                <Heading as="h4" size="sm" mt={4} mb={2}>
                    Tracking Technologies and Cookies
                </Heading>
                <Text>
                    We use Cookies and similar tracking technologies to track the activity on Our Service and
                    store certain information. Tracking technologies used are beacons, tags, and scripts to
                    collect and track information and to improve and analyze Our Service. The technologies We
                    use may include:
                </Text>
                <UnorderedList pl={6}>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Cookies or Browser Cookies.
                        </Text>{' '}
                        A cookie is a small file placed on Your Device. You can instruct Your browser to refuse
                        all Cookies or to indicate when a Cookie is being sent. However, if You do not accept
                        Cookies, You may not be able to use most parts of our Service. Unless you have adjusted
                        Your browser setting so that it will refuse Cookies, our Service may use Cookies.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your
                    personal computer or mobile device when You go offline, while Session Cookies are deleted
                    as soon as You close Your web browser.
                </Text>
                <Text>
                    We use both Session and Persistent Cookies for the purposes set out below:
                </Text>
                <UnorderedList pl={6}>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Necessary / Essential Cookies
                        </Text>
                        <UnorderedList pl={6}>
                            <ListItem>Type: Session Cookies</ListItem>
                            <ListItem>Administered by: Us</ListItem>
                            <ListItem>
                                Purpose: These Cookies are essential to provide You with services available through
                                the Website and to enable You to use some of its features. They help to authenticate
                                users and prevent fraudulent use of user accounts. Without these Cookies, the
                                services that You have asked for cannot be provided, and We only use these Cookies
                                to provide You with those services.
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Functionality Cookies
                        </Text>
                        <UnorderedList pl={6}>
                            <ListItem>Type: Persistent Cookies</ListItem>
                            <ListItem>Administered by: Us</ListItem>
                            <ListItem>
                                Purpose: These Cookies allow us to remember choices You make when You use the
                                Website, such as remembering your login details or language preference. The purpose
                                of these Cookies is to provide You with a more personal experience and to avoid You
                                having to re-enter your preferences every time You use the Website.
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                    <ListItem>
                        <Text as="span" fontWeight="bold">
                            Analytics Cookies
                        </Text>
                        <UnorderedList pl={6}>
                            <ListItem>Type: Session Cookies</ListItem>
                            <ListItem>Administered by: Google</ListItem>
                            <ListItem>
                                Purpose: These Cookies allow us to monitor your usage data about the platform. This
                                helps us improve our platform. You can opt out from this using the{' '}
                                <Link
                                    href="https://tools.google.com/dlpage/gaoptout"
                                    isExternal
                                    color={headingColor}
                                >
                                    Google Analytics Opt-out Browser Add-on
                                </Link>
                                .
                            </ListItem>
                        </UnorderedList>
                    </ListItem>
                </UnorderedList>


                <Heading as="h2" size="md" mt={6} mb={2} color={headingColor}>
                    Contact Us
                </Heading>
                <Text>If you have any questions about this Privacy Policy, You can contact us:</Text>
                <UnorderedList pl={6}>
                    <ListItem>
                        By email:{' '}
                        <Link href="mailto:casprep.contact@gmail.com" color={headingColor}>
                            casprep.contact@gmail.com
                        </Link>
                    </ListItem>
                </UnorderedList>
            </Stack>
        </Box>
    );
};

export default PrivacyPolicy;