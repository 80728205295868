// src/screens/AnswerQuestionPage.tsx

import React, { useState, useEffect, useMemo } from "react";
import {
    Box,
    Spinner,
    Alert,
    AlertIcon,
    Select,
    HStack,
    VStack,
    Text,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge,
    IconButton,
    Flex,
    useToast, Link,
} from "@chakra-ui/react";
import { ArrowUpDownIcon } from "@chakra-ui/icons";
import {CasperScenario} from "../../models/Models";
import {CasperAPI} from "../../APIs/CasperAPI";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../utils/firebase/authContext";

interface TableQuestionProps {
    scenarios: CasperScenario[];
}

const TableQuestion: React.FC<TableQuestionProps> = ({ scenarios }) => {
    const [sortConfig, setSortConfig] = useState<{ key: keyof CasperScenario; direction: string } | null>(null);
    const navigate = useNavigate();

    const sortedQuestions = useMemo(() => {
        let sortableQuestions = [...scenarios];
        if (sortConfig !== null) {
            sortableQuestions.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableQuestions;
    }, [scenarios, sortConfig]);

    const requestSort = (key: keyof CasperScenario) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return (
        <Box
            overflowX="auto"
            width="100%"
            boxShadow="md"
            borderRadius="md"
            borderWidth="1px"
            borderColor="primary.200"
            bg="background"
        >
            <Table variant="simple" colorScheme="gray">
                <Thead bg="secondary.50">
                    <Tr>
                        <Th>Number</Th>
                        <Th>Scenario Title</Th>
                        <Th>
                            <HStack>
                                <Text>Category</Text>
                                <IconButton
                                    aria-label="Sort by Category"
                                    icon={<ArrowUpDownIcon />}
                                    size="xs"
                                    variant="ghost"
                                    onClick={() => requestSort('category')}
                                />
                            </HStack>
                        </Th>
                        <Th>Action</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedQuestions.map((scenario, index) => (
                        <Tr key={scenario.int_id} bg={index % 2 === 0 ? 'background' : 'secondary.50'}>
                            <Td>
                                <Badge
                                    variant="solid"
                                    bg="primary.100"
                                    color="text"
                                >
                                    {String(scenario.int_id).padStart(4, '0')}
                                </Badge>
                            </Td>
                            <Td minW="250px">{scenario.scenario_title}</Td>
                            <Td>{scenario.category}</Td>
                            <Td>
                                <Button
                                    size="sm"
                                    colorScheme="primary"
                                    variant="outline"
                                    onClick={() => navigate(`${window.location.pathname}/${scenario._id.toHexString()}`)}
                                    _hover={{ bg: 'primary.600', color: 'grey' }}
                                >
                                    Select
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
            {scenarios.length <= 8 && (
                <Link>
                    <Alert onClick={() => { navigate('/pricing'); }}>
                        Upgrade plan for more questions!
                    </Alert>
                </Link>
            )}
        </Box>
    );
};

const ScenarioList: React.FC = () => {
    const user = useAuth().user;
    const [scenarios, setScenarios] = useState<CasperScenario[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [categoryFilter, setCategoryFilter] = useState<string>("");

    const fetchQuestions = async () => {
        try {
            const response: CasperScenario[] = await CasperAPI.get_casper_scenarios_v1();
            if (response) {
                const sortedScenarios = response.sort((a, b) => a.int_id - b.int_id);
                setScenarios(sortedScenarios);
            }
            setLoading(false);
        } catch (err) {
            setError("Failed to load scenarios");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchQuestions().then();
    }, [user]);

    const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setCategoryFilter(event.target.value);
    };

    const getUniqueCategories = (scenarios: CasperScenario[]) => {
        const categories = scenarios.map(scenario => scenario.category);
        return Array.from(new Set(categories));
    };

    const filteredQuestions = useMemo(() => {
        return scenarios.filter((scenario) => {
            return (
                (categoryFilter === "" || scenario.category === categoryFilter)
            );
        });
    }, [scenarios, categoryFilter]);

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh" bg="background">
                <Spinner size="xl" color="primary.500" />
            </Flex>
        );
    }

    if (error) {
        return (
            <Box
                p={6}
                w={{ base: "90%", md: "80%", lg: "70%" }}
                mx="auto"
                mt={8}
                boxShadow="lg"
                borderWidth="1px"
                borderRadius="lg"
                borderColor="primary.200"
                bg="background"
            >
                <Alert status="error">
                    <AlertIcon />
                    {error}
                </Alert>
            </Box>
        );
    }

    return (
        <Box
            p={6}
            w={{ base: "90%", md: "80%", lg: "70%" }}
            mx="auto"
            mt={8}
            boxShadow="lg"
            borderWidth="1px"
            borderRadius="lg"
            borderColor="primary.200"
            bg="background"
            minW={"800px"}
        >
            <VStack spacing={6} align="stretch">
                <Text fontSize="2xl" fontWeight="bold" color="text" textAlign="center">
                    Select a Question to Evaluate
                </Text>
                <HStack spacing={4} flexWrap="wrap">
                    <Select
                        placeholder="Filter by Category"
                        onChange={handleCategoryChange}
                        bg="secondary.50"
                        color="text"
                        borderColor="primary.200"
                        _hover={{ borderColor: 'primary.500' }}
                        _focus={{ borderColor: 'primary.500', boxShadow: 'none' }}
                    >
                        {getUniqueCategories(scenarios).map(category => (
                            <option key={category} value={category}>{category}</option>
                        ))}
                    </Select>
                </HStack>
                {filteredQuestions.length > 0 ? (
                    <TableQuestion scenarios={filteredQuestions}/>
                ) : (
                    <Text fontSize="lg" color="text" textAlign="center">
                        No scenarios match the selected filters.
                    </Text>
                )}
            </VStack>
        </Box>
    );
};

export default ScenarioList;