import React from 'react';
import {
    Box,
    Heading,
    Text,
    Stack,
    Link,
    useColorModeValue,
} from '@chakra-ui/react';

const LegalPolicy: React.FC = () => {
    const textColor = useColorModeValue('text', 'text');
    const headingColor = useColorModeValue('primary.500', 'primary.500');

    return (
        <Box p={6} color={textColor}>
            <Heading as="h1" mb={4} color={headingColor}>
                CasPrep Legal Policy
            </Heading>

            <Stack spacing={4}>
                <Text>
                    CasPrep is not affiliated with any academic institutions. The Casper® test is delivered by Altus Assessments at{' '}
                    <Link href="https://takecasper.com" isExternal color={headingColor}>
                        takecasper.com
                    </Link>
                    . This website has been created without any knowledge of the actual Casper® scoring system or the way Altus Assessments constructs its questions. Altus Assessments and CasPrep have no relationship whatsoever, whether by way of affiliation, endorsement, or otherwise.
                </Text>

                <Text>
                    CasPrep does not guarantee improvements in the user's performance on the real Casper® and is not only meant as a practice aid.
                </Text>

                <Text>
                    Information users input into the CasPrep database is strictly confidential and will not be shared with any outside users or organizations.
                </Text>

                <Text>
                    The scenarios, questions, algorithms, graphics, and brand name are intellectual property of CasPrep and are not to be used outside of CasPrep without direct consent from the CasPrep administration.
                </Text>

                <Text>
                    Please email{' '}
                    <Link href="mailto:casprep.contact@gmail.com" color={headingColor}>
                        casprep.contact@gmail.com
                    </Link>{' '}
                    with any concerns about our platform.
                </Text>
            </Stack>
        </Box>
    );
};

export default LegalPolicy;