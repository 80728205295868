// src/pages/Login/Login.tsx

import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Center,
    VStack,
    Text,
    Link,
    Heading,
    Flex,
    Image,
    useColorModeValue,
} from '@chakra-ui/react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebase/firebaseConfig';
import { FcGoogle } from 'react-icons/fc';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { UsersAPI } from '../../APIs/UsersAPI';
import { CustomToaster } from '../../components/CustomToaster';
import { useAuth } from '../../utils/firebase/authContext';

const Login = () => {
    const navigate = useNavigate();
    const { user } = useAuth();

    // Determine the background image based on the current color mode
    const bgImage = useColorModeValue(
        "url('/images/dashboard_1_light.png')",
        "url('/images/dashboard_1_dark.png')",
    );

    useEffect(() => {
        // Redirect to home if user is already logged in
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const full_name = user.displayName ?? undefined;
            const email = user.email ?? undefined;
            const photo_url = user.photoURL ?? undefined;

            // Check if the user exists in the backend
            let dbUser;
            try {
                dbUser = await UsersAPI.get_self();
            } catch (error) {
                console.log("User not found, creating new user...");
            }

            if (dbUser) {
                CustomToaster.success('Login successful.', "You've successfully logged in.");
                navigate('/'); // Navigate after confirming user exists
            } else {
                // Create a new user in the backend
                const res = await UsersAPI.create_user({
                    full_name,
                    email,
                    photo_url,
                });

                if (res) {
                    CustomToaster.success('Account created.', "Your account has been created successfully.");
                    navigate('/'); // Navigate after successful creation
                    window.location.reload();
                }
            }
        } catch (error) {
            CustomToaster.error('Login failed.', 'Unable to login with Google.');
            console.error('Failed to login:', error);
        }
    };

    return (
        <Box position="relative" height="100vh">
            {/* Blurred Background */}
            <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bgImage={bgImage}
                bgSize="cover"
                bgPosition="center"
                filter="blur(2px)"
                zIndex={-1}
            />

            {/* Login Content */}
            <Center height="100%">
                <Box
                    p={8}
                    minW="400px"
                    minH="500px"
                    maxWidth="md"
                    borderWidth={1}
                    borderRadius="lg"
                    boxShadow="lg"
                    bg="background"
                    _dark={{ bg: 'dark.background' }}
                    // Optional: Adjust or remove the backdropFilter if not needed
                    // backdropFilter="blur(5px)"
                >
                    <Flex justify="flex-end">
                        <ColorModeSwitcher />
                    </Flex>
                    <VStack spacing={4}>
                        <Box>
                            <Image
                                src="/logo1.svg"
                                boxSize="80px"
                                mx="auto"
                                borderRadius="full"
                                alt="CasPrep Logo"
                            />
                        </Box>
                        <Heading as="h1" size="2xl" color="text" textAlign="center">
                            CasPrep
                        </Heading>
                        <Text fontSize="lg" fontWeight="bold" color="text" textAlign="center">
                            Welcome to the CasPrep Login Portal
                        </Text>
                        <Text fontSize="md" color="text" textAlign="center">
                            Your new AI best friend for Casper preparation.
                        </Text>
                        <Text fontSize="md" color="text" textAlign="center">
                            Casper preparation just got:
                        </Text>
                        <Text fontSize="md" color="primary.500" textAlign="center">
                            Easier, better, and more affordable!
                        </Text>
                        <VStack spacing={1} align="center">
                            <Text fontSize="md" color="text">
                                📝 Over 175 questions
                            </Text>
                            <Text fontSize="md" color="text">
                                ⚡️ Instant personalized feedback
                            </Text>
                            <Text fontSize="md" color="text">
                                📖 Track your progress
                            </Text>
                        </VStack>
                        <Button
                            onClick={handleGoogleLogin}
                            width="full"
                            leftIcon={<FcGoogle />}
                            variant="solid"
                        >
                            Login with Google
                        </Button>
                        <Text fontSize="md" color="text">
                            Create an account to start practicing!
                        </Text>
                    </VStack>
                </Box>
            </Center>
        </Box>
    );
};

export default Login;