// src/pages/ContactUsPage.tsx

import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Textarea,
    useColorModeValue,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
} from '@chakra-ui/react';
import { CustomToaster } from "../components/CustomToaster";
import {FormsAPI} from "../APIs/FormsAPI"; // Added for custom toasts

const ContactUsPage = () => {
    // Form state
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission status
    const [showSuccessAlert, setShowSuccessAlert] = useState(false); // State to manage success alert visibility

    // Colors using useColorModeValue for light/dark mode
    const bgColor = useColorModeValue('primary.50', 'primary.800');
    const textColor = useColorModeValue('text', 'text');
    const hoverBgColor = useColorModeValue('primary.100', 'primary.600');
    const buttonGradient = useColorModeValue(
        'linear(to-r, primary.500, primary.400)',
        'linear(to-r, primary.700, primary.600)'
    );
    const buttonHoverGradient = useColorModeValue(
        'linear(to-r, primary.600, primary.500)',
        'linear(to-r, primary.800, primary.700)'
    );

    // Handle form field changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Email validation helper function
    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const { name, email, message } = formData;

        // Input validation
        if (!name || !email || !message) {
            CustomToaster.warning("All fields are required.", "Please fill out all fields before submitting.");
            return;
        }

        if (!isValidEmail(email)) {
            CustomToaster.warning("Invalid Email.", "Please enter a valid email address.");
            return;
        }

        setIsSubmitting(true);

        try {
            const messageResponse = await FormsAPI.submit_contact_us_form(name, email, message);
            CustomToaster.success("Form Submitted!", messageResponse);
            setFormData({ name: '', email: '', message: '' }); // Clear the form upon success
            setShowSuccessAlert(true); // Show the success alert
        } catch (error: any) {
            CustomToaster.error(
                "Submission Failed.",
                error.response?.data?.message || "An unexpected error occurred. Please try again later."
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            bg={bgColor}
            color={textColor}
            p={{ base: 4, md: 8 }}  // Padding adjusts for mobile and larger screens
            borderRadius="md"
            maxW={{ base: '90%', md: 'md' }}  // Responsive width, full width on mobile, medium width on desktop
            mx="auto"
            mt={10}
            shadow="md"
        >
            {showSuccessAlert && (
                <Alert
                    status='success'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                    mb={6}
                    borderRadius="md"
                >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        Application submitted!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                        Thanks for submitting your application. Our team will get back to you soon.
                    </AlertDescription>
                    <CloseButton
                        position="absolute"
                        right="8px"
                        top="8px"
                        onClick={() => setShowSuccessAlert(false)}
                    />
                </Alert>
            )}
            <form onSubmit={handleSubmit}>
                <FormControl isRequired mb={4}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Name</FormLabel> {/* Adjust font size for mobile */}
                    <Input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        bg={hoverBgColor}
                        color={textColor}
                        fontSize={{ base: 'sm', md: 'md' }}  // Input font size adjusts for mobile
                    />
                </FormControl>

                <FormControl isRequired mb={4}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Email</FormLabel>
                    <Input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        bg={hoverBgColor}
                        color={textColor}
                        fontSize={{ base: 'sm', md: 'md' }}
                    />
                </FormControl>

                <FormControl isRequired mb={4}>
                    <FormLabel fontSize={{ base: 'sm', md: 'md' }}>Message</FormLabel>
                    <Textarea
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        bg={hoverBgColor}
                        color={textColor}
                        fontSize={{ base: 'sm', md: 'md' }}
                    />
                </FormControl>

                <Button
                    type="submit"
                    bgGradient={buttonGradient}  // Gradient background for the button
                    color={textColor}
                    _hover={{
                        bgGradient: buttonHoverGradient,  // Different gradient on hover
                        boxShadow: 'lg',  // Add shadow effect on hover
                    }}
                    padding="1.5rem"
                    borderRadius="full"
                    fontWeight="bold"
                    fontSize={{ base: 'md', md: 'lg' }}  // Adjust button font size for mobile
                    mt={4}
                    width="full"  // Button is full width on all screen sizes
                    transition="all 0.3s ease"  // Smooth transition effect
                    boxShadow="md"  // Initial shadow for button
                    isLoading={isSubmitting} // Show loading spinner when submitting
                    disabled={isSubmitting} // Disable the button while submitting
                >
                    Submit
                </Button>
            </form>
        </Box>
    );
};

export default ContactUsPage;