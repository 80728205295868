// src/theme/index.ts

import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { lightModeColors, darkModeColors } from './colors';

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    colors: {
        light: lightModeColors,
        dark: darkModeColors,
    },
    semanticTokens: {
        colors: {
            background: {
                default: lightModeColors.background,
                _dark: darkModeColors.background,
            },
            text: {
                default: lightModeColors.text,
                _dark: darkModeColors.text,
            },
            primary: {
                default: lightModeColors.primary[500],
                _dark: darkModeColors.primary[500],
            },
            // Add other tokens as needed
        },
    },
    fonts: {
        body: `"Rajdhani", sans-serif`,
        heading: `"Rajdhani", sans-serif`,
    },
});

export default theme;