// src/components/FeatureComingSoon.tsx

import React from "react";
import {
    Box,
    Flex,
    Avatar,
    Badge,
    Stack,
    Heading,
    Text,
    useColorModeValue,
    Button,
} from "@chakra-ui/react";

const FeatureComingSoon: React.FC = () => {
    // **1. Theme-Based Colors Using useColorModeValue**
    const primaryColor = useColorModeValue("primary", "primary");
    const primaryHoverColor = useColorModeValue("primary.600", "primary.600");
    const textColor = useColorModeValue("text", "text");
    const backgroundColor = useColorModeValue("background", "background");
    const hoverBg = useColorModeValue("primary.50", "primary.50");

    return (
        <Box maxW="600px" mx="auto" mt={8} bg={backgroundColor} p={4} borderRadius="md" boxShadow="md">
            <Flex alignItems="center" justifyContent="center" direction="column">
                <Heading size="lg" color={textColor}>
                    Feature Coming Soon
                </Heading>
                <Badge colorScheme="orange" mt={2}>
                    Stay tuned for updates
                </Badge>
            </Flex>
            <Stack spacing={4} mt={6} textAlign="center">
                <Text color={textColor}>
                    We are working hard to bring you this new feature. Keep an eye out for updates as we improve your experience!
                </Text>
            </Stack>
        </Box>
    );
};

export default FeatureComingSoon;