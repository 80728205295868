import FLASK_HTTPS from "./_FLASK_API";
import { AxiosResponse } from "axios";
import { ErrorHandler } from "../utils/error";

export namespace UtilsAPI {
    const route_name = "/utils";  // Changed `let` to `const` since the value won't change

    // Health Check
    export const system_status_check = async (): Promise<AxiosResponse<any>> => {
        try {
            return await FLASK_HTTPS.get(`${route_name}/system_status_check`);
        } catch (error: any) {
            ErrorHandler.handleAPIError(error, 'Error checking API health');
            // Throw the original error to preserve context
            throw error;
        }
    };
}