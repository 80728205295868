// src/pages/LandingPage.tsx

import React from 'react';
import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    VStack,
    SimpleGrid,
    Image,
    useColorModeValue, Spacer,
} from '@chakra-ui/react';
import Footer from "../components/Footer";

export const LandingPage: React.FC = () => {
    // Conditionally set the hero image based on color mode
    const image_1 = useColorModeValue(
        "url('/images/dashboard_1_light.png')",
        "url('/images/dashboard_1_dark.png')",
    );

    return (
        <Box bg="background" color="text">
            {/* Header */}
            <Flex
                as="header"
                align="center"
                justify="space-between"
                padding={4}
                borderBottomWidth={1}
                borderColor={useColorModeValue('light.primary.200', 'dark.primary.800')}
            >
                <Image
                    src="/logo1.svg"
                    boxSize="40px"
                    mx="auto"
                    borderRadius="full"
                    alt="CasPrep Logo"
                />
                <Spacer/>
                <Button variant="solid">Get Started / Login</Button>
            </Flex>

            {/* Hero Section */}
            <Flex
                direction={{ base: 'column', md: 'row' }}
                align="center"
                justify="center"
                padding={8}
                minH="70vh"
            >
                <Box flex="1" textAlign={{ base: 'center', md: 'left' }}>
                    <Heading size="2xl" mb={4}>
                        Welcome to the Ultimate Learning Platform for Casper
                    </Heading>
                    <Text fontSize="xl" mb={6}>
                        Access to 300+ questions, powerful analytics, and AI-enhanced feedback.
                    </Text>
                    <Button size="lg" variant="solid">
                        Get Started
                    </Button>
                </Box>
                <Box
                    flex="1"
                    display={{ base: 'none', md: 'block' }}
                    ml={{ md: 8 }}
                    bgImage={image_1}
                    bgSize="cover"
                    bgPosition="center"
                    width="100%"
                    height="300px"
                    borderRadius="md"
                />
            </Flex>

            {/* Features Section */}
            <Box padding={8}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                    <Box textAlign="center">
                        <Heading size="md" mb={4}>
                            Powerful Performance
                        </Heading>
                        <Text>
                            Experience blazing-fast speeds with our optimized platform, designed to handle your toughest challenges.
                        </Text>
                    </Box>
                    <Box textAlign="center">
                        <Heading size="md" mb={4}>
                            Top-Notch Security
                        </Heading>
                        <Text>
                            We employ the latest security protocols to keep your data safe and secure at all times.
                        </Text>
                    </Box>
                    <Box textAlign="center">
                        <Heading size="md" mb={4}>
                            Comprehensive Support
                        </Heading>
                        <Text>
                            Our dedicated support team is here to help you with any issues you may encounter.
                        </Text>
                    </Box>
                </SimpleGrid>
            </Box>

            {/* What You Get Section */}
            <Box bg={useColorModeValue('light.primary.50', 'dark.primary.900')} padding={8}>
                <Heading textAlign="center" mb={6}>
                    What You Get
                </Heading>
                <VStack spacing={4} align="start" maxW="800px" mx="auto">
                    <Text>• 300+ questions tailored to your needs</Text>
                    <Text>• Powerful analytics to track your progress</Text>
                    <Text>• AI-enhanced feedback for continuous improvement</Text>
                    <Text>• Access to a vibrant community of learners</Text>
                    <Text>• No credit card required to get started</Text>
                </VStack>
                <Flex justify="center" mt={6}>
                    <Button size="lg" variant="solid">
                        Get Started
                    </Button>
                </Flex>
            </Box>

            {/* Call to Action */}
            <Box padding={8} textAlign="center">
                <Heading mb={4}>Ready to Elevate Your Learning?</Heading>
                <Button size="lg" variant="solid">
                    Get Started Now
                </Button>
            </Box>

            {/* Testimonials Section */}
            <Box bg={useColorModeValue('light.primary.50', 'dark.primary.900')} padding={8}>
                <Heading textAlign="center" mb={6}>
                    People Love Us
                </Heading>
                <Text textAlign="center" mb={4}>
                    You're in good company
                </Text>
                <Text textAlign="center" mb={6}>
                    See why over 1000+ students use CasPrep to manage their CasPrep studies!
                </Text>
                {/* Placeholder for testimonials */}
            </Box>

            {/* Footer */}
            <Footer/>
        </Box>
    );
};