import FLASK_HTTPS from "./_FLASK_API";
import {Plan, StripeSubscription, Subscription} from "../models/Payment";
import { ErrorHandler } from "../utils/error";
import {ObjectId} from "bson";

export namespace PaymentsAPI {
    let route_name = "/payments";

    export const get_self_plans = async (): Promise<Plan[] | undefined> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/get_self_plans`);
            return res.data.map((planData: any) => new Plan(planData));
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Unable to fetch plans');
            throw(error);
        }
    };

    export const get_current_plan = async (sleep: number = 0): Promise<{ plan: Plan; subscription: Subscription; stripe_subscription?: StripeSubscription } | undefined> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/get_current_plan/${sleep}`);
            return {
                plan: new Plan(res.data.plan),
                subscription: new Subscription(res.data.subscription),
                stripe_subscription: res.data.stripe_subscription ? new StripeSubscription(res.data.stripe_subscription) : undefined,
            };
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Unable to fetch plan, subscription, and Stripe subscription');
            throw(error);
        }
    };

    export const change_plan = async (plan_id: ObjectId): Promise<string | undefined> => {
        try {
            // Send the POST request to change the plan
            const res = await FLASK_HTTPS.post(`${route_name}/change_plan`, { plan_id });

            // Check if the response contains the checkout URL for paid plans
            if (res.data.checkout_url) {
                return res.data.checkout_url as string;  // Return the Stripe checkout URL for paid plans
            }

            // If no checkout URL, check if we have a success message for a free plan change
            if (res.data.message) {
                console.log(res.data.message);  // Log the success message, e.g., "Successfully changed to free plan"
                return undefined;  // No checkout URL needed for free plans, returning undefined
            }

            // Handle the case where neither a URL nor a success message is returned (unexpected case)
            throw new Error('Unexpected response from the change_plan API');
        } catch (error) {
            // Handle any errors during the API call
            ErrorHandler.handleAPIError(error, 'Unable to change the plan');
            throw error;  // Re-throw the error after handling it
        }
    };
}