import * as React from "react";
import { Box, Button, Textarea, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { CasperAPI } from "../APIs/CasperAPI"; // Adjust the import path as necessary

const QuestionBox: React.FC = () => {
    const [question, setQuestion] = useState<string>("");
    const [response, setResponse] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = event.target.value;
        if (value.length <= 300) {
            setQuestion(value);
        }
    };

    const handleAskQuestion = async () => {
        // setLoading(true);
        // setError(null);
        // try {
        //     const res = await CasperAPI.ask_casper_scenario_v1(question);
        //     if (res) {
        //         setResponse(`You asked: "${res.received_scenario}". Here's a response: "${res.response}"`);
        //     }
        // } catch (err) {
        //     setError("An error occurred while asking the question.");
        // } finally {
        //     setLoading(false);
        // }
    };

    const boxBg = useColorModeValue("white", "gray.700");
    const responseBg = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    return (
        <Box
            p={6}
            bg={boxBg}
            borderRadius="md"
            w={{ base: "90%", md: "80%", lg: "70%" }}
            maxW="4xl"
            mx="auto"
            mt={8}
            shadow="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            <VStack spacing={4}>
                <Text fontSize="xl" fontWeight="bold">
                    Ask a Question
                </Text>
                <Textarea
                    value={question}
                    onChange={handleQuestionChange}
                    placeholder="Type your question here"
                    variant="filled"
                    size="lg"
                    resize="vertical"
                    minH="120px"
                />
                <Text alignSelf="flex-end" fontSize="sm" color="gray.500">
                    {question.length}/300
                </Text>
                <Button
                    onClick={handleAskQuestion}
                    colorScheme="blue"
                    isLoading={loading}
                    loadingText="Asking"
                    width="100%"
                    size="lg"
                >
                    Ask
                </Button>
                {error && (
                    <Text color="red.500" mt={2}>
                        {error}
                    </Text>
                )}
                {response && (
                    <Box mt={4} p={4} bg={responseBg} borderRadius="md" shadow="md" width="100%">
                        <Text whiteSpace="pre-wrap">{response}</Text>
                    </Box>
                )}
            </VStack>
        </Box>
    );
};

export default QuestionBox;