import * as React from "react";
import { Box, Text } from "@chakra-ui/react";

const Settings: React.FC = () => {
    return (
        <Box>
            <Text>Settings Page</Text>
        </Box>
    );
};

export default Settings;