import {
    Box,
    HStack,
    Text,
    VStack,
    List,
    ListItem,
} from '@chakra-ui/react';
import React from 'react';
import { StripeSubscription } from '../../models/Payment';

export interface StripeSubscriptionCardProps {
    subscription: StripeSubscription;
    borderColor: string;
    bgColor: string;
}

export const StripeSubscriptionCard: React.FC<StripeSubscriptionCardProps> = ({
                                                                                  subscription,
                                                                                  borderColor,
                                                                                  bgColor,
                                                                              }) => {
    return (
        <Box
            mb={4}
            shadow="base"
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="xl"
            alignSelf={{ base: 'center', lg: 'flex-start' }}
        >
            <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl" textAlign="center">
                    Active Plan
                </Text>
                <HStack justifyContent="center">
                    <Text fontSize="3xl" fontWeight="600">
                        $
                    </Text>
                    <Text fontSize="5xl" fontWeight="900">
                        {(subscription.plan.amount / 100).toFixed(2)}
                    </Text>
                    <Text fontSize="3xl" color="gray.500">
                        {subscription.plan.interval
                            ? `/${subscription.plan.interval}`
                            : ''}
                    </Text>
                </HStack>
            </Box>
            <VStack bg={bgColor} py={4} borderBottomRadius="xl">
                <List spacing={3} textAlign="left" px={12}>
                    <ListItem>
                        <Text fontWeight="600">Status:</Text> {subscription.status}
                    </ListItem>
                    <ListItem>
                        <Text fontWeight="600">Subscription ID:</Text> {subscription.id}
                    </ListItem>
                    <ListItem>
                        <Text fontWeight="600">Start Date:</Text>{' '}
                        {new Date(1000 * Number(subscription.start_date)).toLocaleDateString()}
                    </ListItem>
                    <ListItem>
                        <Text fontWeight="600">Current Period Ends:</Text>{' '}
                        {new Date(1000 * Number(subscription.current_period_end)).toLocaleDateString()}
                    </ListItem>
                    <ListItem>
                        <Text fontWeight="600">Cancel at Period End:</Text>{' '}
                        {subscription.cancel_at_period_end ? 'Yes' : 'No'}
                    </ListItem>
                </List>
            </VStack>
        </Box>
    );
};