// src/components/StatusPage.tsx

import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    VStack,
    HStack,
    Tag,
    Flex,
    Divider,
    useColorModeValue,
    Icon,
    Grid, Badge,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, CloseIcon } from '@chakra-ui/icons';
import { UtilsAPI } from "../APIs/UtilsAPI";
import { AxiosResponse } from "axios";


export interface CategoryServices {
    [serviceName: string]: string;
}

// Define the overall status response structure
export interface StatusResponse {
    status: 'healthy' | 'degraded';
    timestamp: string;
    categories: {
        [categoryName: string]: CategoryServices;
    };
}

// Access backend API base URL from environment variables
const API_BASE_URL = process.env.REACT_APP_FLASK_API_ADDRESS || 'API URL not configured';

const StatusPage: React.FC = () => {
    const [status, setStatus] = useState<StatusResponse | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchHealthStatus = async () => {
        setLoading(true);
        setError(null); // Reset error state
        try {
            const res: AxiosResponse<StatusResponse> = await UtilsAPI.system_status_check();
            setStatus(res.data);
        } catch (err: any) {
            console.error('API Error:', err); // Log error for debugging
            setError('Failed to fetch system status. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHealthStatus();
        // Optional: Auto-refresh every 5 minutes
        const interval = setInterval(fetchHealthStatus, 5 * 60 * 1000); // 5 minutes
        return () => clearInterval(interval);
    }, []);

    // Function to determine the color scheme based on service status
    const getColorScheme = (serviceStatus: string) =>
        serviceStatus.startsWith('error') ? 'red' : 'green';

    // Function to get the appropriate icon based on service status
    const getStatusIcon = (serviceStatus: string) => {
        if (serviceStatus.startsWith('error')) {
            return <CloseIcon color="red.500" />;
        }
        return <CheckCircleIcon color="green.500" />;
    };

    // Function to determine category status based on its services
    const getCategoryStatus = (services: CategoryServices) => {
        for (const status of Object.values(services)) {
            if (status.startsWith('error')) {
                return { status: 'degraded', icon: <WarningIcon color="yellow.500" /> };
            }
        }
        return { status: 'healthy', icon: <CheckCircleIcon color="green.500" /> };
    };

    // Use Chakra's `useColorModeValue` to adapt colors for light and dark modes
    const cardBg = useColorModeValue('gray.50', 'gray.800');
    const cardBorder = useColorModeValue('gray.200', 'gray.700');
    const categoryBg = useColorModeValue('gray.100', 'gray.700');
    const serviceBg = useColorModeValue('white', 'gray.600');

    // Function to render individual service cards
    const renderServiceCard = (service: string, serviceStatus: string) => (
        <HStack
            key={service}
            spacing={3}
            p={3}
            bg={serviceBg}
            borderRadius="md"
            boxShadow="sm"
            borderWidth="1px"
            borderColor={cardBorder}
            justifyContent="space-between"
        >
            <HStack>
                <Icon as={serviceStatus.startsWith('error') ? CloseIcon : CheckCircleIcon} color={serviceStatus.startsWith('error') ? 'red.500' : 'green.500'} />
                <Text>{service}</Text>
            </HStack>
            <Tag colorScheme={getColorScheme(serviceStatus)}>
                {serviceStatus.startsWith('error') ? 'Error' : 'Online'}
            </Tag>
        </HStack>
    );

    if (loading) {
        return (
            <Box textAlign="center" mt="20">
                <Spinner size="xl" />
                <Text mt="4">Checking system status...</Text>
                <Text mt="2" fontSize="sm" color="gray.500">
                    Backend API: {API_BASE_URL}
                </Text>
            </Box>
        );
    }

    if (error) {
        return (
            <Box textAlign="center" mt="20" p="6">
                <Alert status="error" borderRadius="md">
                    <AlertIcon />
                    <VStack align="start">
                        <AlertTitle>Error</AlertTitle>
                        <AlertDescription>{error}</AlertDescription>
                    </VStack>
                </Alert>
                <Text mt="4" fontSize="sm" color="gray.500">
                    Backend API: {API_BASE_URL}
                </Text>
            </Box>
        );
    }

    return (
        <Box maxW="800px" mx="auto" p={4}>
            {status && (
                <VStack spacing={6} align="stretch">
                    {/* Overall Status */}
                    <Alert status={status.status === 'healthy' ? 'success' : 'warning'} borderRadius="md">
                        <AlertIcon />
                        <Flex align="center" flexWrap="wrap">
                            <AlertTitle mr={2}>
                                {status.status === 'healthy'
                                    ? 'All Systems Operational'
                                    : 'Service Issues Detected'}
                            </AlertTitle>
                            <Tag colorScheme={status.status === 'healthy' ? 'white' : 'yellow'}  margin={"5px"}>
                                {status.status.toUpperCase()}
                            </Tag>
                            <Tag colorScheme={'blue'} margin={"5px"}>
                                Backend API: {API_BASE_URL}
                            </Tag>
                        </Flex>
                    </Alert>

                    {/* Timestamp */}
                    <Text fontSize="sm" color="gray.500" textAlign="right">
                        Checked at: {new Date(status.timestamp).toLocaleString()}
                    </Text>

                    {/* Categories and Services */}
                    <VStack spacing={6} align="stretch">
                        {Object.entries(status.categories).map(([category, services]) => {
                            const categoryStatus = getCategoryStatus(services);
                            return (
                                <Box
                                    key={category}
                                    p={4}
                                    bg={categoryBg}
                                    borderRadius="md"
                                    borderWidth="1px"
                                    borderColor={cardBorder}
                                    boxShadow="sm"
                                >
                                    <Flex align="center" mb={4}>
                                        {categoryStatus.icon}
                                        <Text ml={2} fontSize="lg" fontWeight="bold">
                                            {category}
                                        </Text>
                                        <Tag
                                            ml="auto"
                                            colorScheme={categoryStatus.status === 'healthy' ? 'green' : 'yellow'}
                                        >
                                            {categoryStatus.status.toUpperCase()}
                                        </Tag>
                                    </Flex>
                                    <VStack spacing={4} align="stretch">
                                        {Object.entries(services).map(([service, serviceStatus]) =>
                                            renderServiceCard(service, serviceStatus)
                                        )}
                                    </VStack>
                                </Box>
                            );
                        })}
                    </VStack>
                </VStack>
            )}
        </Box>
    );
};

export default StatusPage;