// src/components/CustomToaster.ts

import { createStandaloneToast, UseToastOptions, ToastId } from '@chakra-ui/react';

// Initialize the standalone toast
const { toast } = createStandaloneToast();

export namespace CustomToaster {
    /**
     * Displays a success toast.
     * @param title - The title of the toast.
     * @param description - The description/message of the toast.
     */
    export const success = (title: string, description: string) => {
        toast({
            title: title,
            description: description,
            status: "success", // Maps to theme.semanticTokens.colors.success
            duration: 5000,
            isClosable: true,
            variant: 'subtle', // Changed from 'top-accent' to 'subtle' for a cleaner look
        });
    };

    /**
     * Displays a warning toast.
     * @param title - The title of the toast.
     * @param description - The description/message of the toast.
     */
    export const warning = (title: string, description: string) => {
        toast({
            title: title,
            description: description,
            status: "warning", // Maps to theme.semanticTokens.colors.warning
            duration: 5000,
            isClosable: true,
            variant: 'subtle',
        });
    };

    /**
     * Displays an error toast.
     * @param title - The title of the toast.
     * @param description - The description/message of the toast.
     */
    export const error = (title: string, description: string) => {
        toast({
            title: title,
            description: description,
            status: "error", // Maps to theme.semanticTokens.colors.error
            duration: 5000,
            isClosable: true,
            variant: 'subtle',
        });
    };

    /**
     * Displays an info toast.
     * @param title - The title of the toast.
     * @param description - The description/message of the toast.
     */
    export const info = (title: string, description: string) => {
        toast({
            title: title,
            description: description,
            status: "info", // Maps to theme.semanticTokens.colors.info
            duration: 5000,
            isClosable: true,
            variant: 'subtle',
        });
    };

    /**
     * Handles promise-based actions with loading, success, and error toasts.
     * @param promise - The promise representing the async action.
     * @param messages - The messages for loading, success, and error states.
     * @returns The resolved or rejected promise.
     */
    export const promise = <T extends unknown>(
        promise: Promise<T>,
        messages: {
            loading: { title: string; description: string };
            success: { title: string; description: string };
            error: { title: string; description: string };
        }
    ): Promise<T> => {
        // Show loading toast
        const loadingToastId: ToastId = toast({
            title: messages.loading.title,
            description: messages.loading.description,
            status: "info",
            duration: null, // Stay visible until the promise resolves or rejects
            isClosable: true,
            variant: 'subtle',
        });

        return promise
            .then((result) => {
                // Update loading toast to success
                toast.update(loadingToastId, {
                    title: messages.success.title,
                    description: messages.success.description,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                    variant: 'subtle',
                });
                return result; // Pass the result back
            })
            .catch((err) => {
                // Update loading toast to error
                toast.update(loadingToastId, {
                    title: messages.error.title,
                    description: messages.error.description,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    variant: 'subtle',
                });
                throw err; // Propagate the error
            });
    };
}