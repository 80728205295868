// src/pages/Signup/Signup.tsx

import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Center,
    VStack,
    Text,
    Link,
    Heading,
    useColorMode,
    Flex,
} from '@chakra-ui/react';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../utils/firebase/firebaseConfig';
import { FcGoogle } from 'react-icons/fc';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';
import { UsersAPI } from '../../APIs/UsersAPI';
import { CustomToaster } from '../../components/CustomToaster';
import { useAuth } from '../../utils/firebase/authContext';

const Signup = () => {
    const navigate = useNavigate();
    const { colorMode } = useColorMode();
    const { user } = useAuth();

    useEffect(() => {
        // Redirect to home if user is already logged in
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const handleGoogleSignup = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const full_name = user.displayName ?? undefined;
            const email = user.email ?? undefined;
            const photo_url = user.photoURL ?? undefined;

            // Check if the user exists in the backend
            let dbUser;
            try {
                dbUser = await UsersAPI.get_self();
            } catch (error) {
                console.log('User not found, creating new user...');
            }

            if (dbUser) {
                CustomToaster.success('Account exists.', "You already have an account.");
                navigate('/'); // Navigate after confirming user exists
            } else {
                // Create a new user in the backend
                const res = await UsersAPI.create_user({
                    full_name,
                    email,
                    photo_url,
                });

                if (res) {
                    CustomToaster.success('Account created.', 'Your account has been created successfully.');
                    navigate('/'); // Navigate after successful creation
                    window.location.reload();
                }
            }
        } catch (error) {
            CustomToaster.error('Signup failed.', 'Unable to sign up with Google.');
            console.error('Failed to sign up:', error);
        }
    };

    return (
        <Center height="100vh" bg="background">
            <Box
                p={8}
                minW={"400px"}
                minH={"400px"}
                maxWidth="md"
                borderWidth={1}
                borderRadius="lg"
                boxShadow="lg"
                bg="background"
                _dark={{ bg: 'dark.background' }}
            >
                <Flex justify="flex-end">
                    <ColorModeSwitcher />
                </Flex>
                <VStack spacing={4}>
                    <Heading as="h1" size="xl" color="text">
                        CasPrep
                    </Heading>
                    <Text fontSize="lg" fontWeight="bold" color="text">
                        Create your CasPrep account
                    </Text>
                    <Text fontSize="md" textAlign="center" color="text">
                        Your journey starts here!
                    </Text>
                    <Button
                        onClick={handleGoogleSignup}
                        width="full"
                        leftIcon={<FcGoogle />}
                        variant="solid"
                        // The Button component uses the theme's variant styles
                    >
                        Sign up with Google
                    </Button>
                    <Text fontSize="sm" color="text">
                        Already have an account?{' '}
                        <Link color="primary" href="/login">
                            Log In
                        </Link>
                    </Text>
                </VStack>
            </Box>
        </Center>
    );
};

export default Signup;