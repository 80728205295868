// src/theme/colors.ts

export const lightModeColors = {
    background: '#FFFFFF', // White
    text: '#111111',       // Slightly greyed black for better readability
    primary: {
        50: '#f9f9f9',
        100: '#f0f0f0',
        200: '#e6e6e6',
        300: '#cccccc',
        400: '#b3b3b3',
        500: '#999999', // Base Grey
        600: '#808080',
        700: '#666666',
        800: '#4d4d4d',
        900: '#333333',
    },
    secondary: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#ececec',
        300: '#e0e0e0',
        400: '#d5d5d5',
        500: '#cccccc',
        600: '#bfbfbf',
        700: '#b3b3b3',
        800: '#a6a6a6',
        900: '#999999',
    },
    // Feedback Colors using shades of grey
    success: {
        500: '#4d4d4d', // Dark Grey
    },
    error: {
        500: '#bfbfbf', // Medium Grey
    },
    warning: {
        500: '#e6e6e6', // Light Grey
    },
    info: {
        500: '#cccccc', // Base Grey
    },
};

export const darkModeColors = {
    background: '#1a1a1a', // Slightly lighter than Near Black for reduced strain
    text: '#FFFFFF',       // White
    primary: {
        50: '#1f1f1f',
        100: '#2c2c2c',
        200: '#383838',
        300: '#474747',
        400: '#555555',
        500: '#666666', // Base Grey
        600: '#7a7a7a',
        700: '#8e8e8e',
        800: '#a3a3a3',
        900: '#b8b8b8',
    },
    secondary: {
        50: '#2c2c2c',
        100: '#393939',
        200: '#474747',
        300: '#555555',
        400: '#636363',
        500: '#737373',
        600: '#858585',
        700: '#979797',
        800: '#a9a9a9',
        900: '#bbbbbb',
    },
    // Feedback Colors using shades of grey
    success: {
        500: '#a3a3a3', // Light Grey
    },
    error: {
        500: '#737373', // Base Grey
    },
    warning: {
        500: '#555555', // Dark Grey
    },
    info: {
        500: '#666666', // Base Grey
    },
};