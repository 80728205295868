// src/api/FormsAPI.ts

import FLASK_HTTPS from "./_FLASK_API";
import {EmailSubscription, FormSubmission, FormSubmissionStatusEnum} from "../models/Models";
import {ErrorHandler} from "../utils/error";
import {ObjectId} from "bson";

export namespace FormsAPI {
    let route_name = "/forms";

    export const subscribe_to_newsletter = async (email: string): Promise<string> => {
        try {
            const res = await FLASK_HTTPS.post(`${route_name}/subscribe`, {
                email
            });
            return res.data.message;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'error');
            throw error; // Re-throw the error after handling
        }
    };

    export const unsubscribe_from_newsletter = async (email: string): Promise<string> => {
        try {
            const res = await FLASK_HTTPS.post(`${route_name}/unsubscribe`, {
                email
            });
            return res.data.message;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'error');
            throw error; // Re-throw the error after handling
        }
    };

    export const get_all_email_subscriptions = async (): Promise<EmailSubscription[]> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/email_subscriptions`);
            // Map the received data to EmailSubscription instances
            return res.data.map((subscriptionData: any) => new EmailSubscription(subscriptionData))
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'error');
            throw error;
        }
    };

    export const submit_contact_us_form = async (name: string, email: string, message: string): Promise<string> => {
        try {
            const res = await FLASK_HTTPS.put(`${route_name}/form_submissions`, {
                name,
                email,
                message
            });
            return res.data.message;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'error');
            throw error; // Re-throw the error after handling
        }
    };

    export const get_all_form_submissions = async (): Promise<FormSubmission[]> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/form_submissions`);
            // Assuming the API returns an array of form submissions
            return res.data.map((submissionData: any) => new FormSubmission(submissionData));
        } catch (error: any) {
            ErrorHandler.handleAPIError(error, 'error');
            throw error;
        }
    };

    export const update_form_status = async (submission_id: string, status: FormSubmissionStatusEnum): Promise<string> => {
        try {
            const res = await FLASK_HTTPS.patch(`${route_name}/form_submissions/${submission_id}/status`, {
                status
            });
            return res.data.message;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'error');
            throw error;
        }
    };
}