import { Box, Heading, Text, Center, Image } from "@chakra-ui/react";

export const ComingSoon = () => {
    return (
        <Center minH="100vh" bg="black" p={4}>
            <Box textAlign="center" color="white">
                <Image
                    src="/logo1.svg"
                    mb={8}
                    boxSize="300px"
                    mx="auto"
                    borderRadius="full"
                />
                <Heading as="h1" size="3xl" mb={4}>
                    Welcome to CasPrep
                </Heading>
                <Text fontSize="xl">
                    Your new AI best friend for Casper preparation!
                </Text>
            </Box>
        </Center>
    );
};