import * as React from "react";
import {
    Box,
    Button,
    Textarea,
    Text,
    useColorModeValue,
    VStack,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    StepTitle,
    StepSeparator,
    useSteps, HStack, CircularProgress, CircularProgressLabel, Spacer,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CasperInteraction, CasperScenario } from "../../models/Models";
import { CasperAPI } from "../../APIs/CasperAPI";
import CasperInteractionComp from "../../components/CasperInteractionComp";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBackIcon} from "@chakra-ui/icons";

const EvaluateCasperBoxV2: React.FC = () => {
    const { scenario_id } = useParams();
    const navigate = useNavigate();

    const [scenario, setScenario] = useState<CasperScenario | null>(null);
    const [answers, setAnswers] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const { activeStep, setActiveStep, goToNext, goToPrevious } = useSteps({
        index: 0,
        count: scenario ? scenario.questions.length : 0,
    });

    const handleAnswerChange =
        (index: number) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newAnswers = [...answers];
            newAnswers[index] = event.target.value;
            setAnswers(newAnswers);
        };

    const handleNext = async () => {
        if (scenario && activeStep < scenario.questions.length - 1) {
            goToNext();
        } else {
            await handleEvaluateAnswer();
        }
    };

    const get_and_open_scenario = async (scenario_id: string) => {
        try {
            const scenario: CasperScenario = await CasperAPI.get_casper_scenario(scenario_id);
            setScenario(scenario);
            setAnswers(Array(scenario.questions.length).fill(""));
        } catch (err) {
            setError("Failed to load scenario");
        }
    };

    const handleEvaluateAnswer = async () => {
        setLoading(true);
        setError(null);
        try {
            if (scenario) {
                const res: CasperInteraction = await CasperAPI.evaluate_casper_scenario(
                    scenario,
                    answers,
                    totalTime - timeLeft
                );
                if (res) {
                    navigate("/history")
                }
            }
        } catch (err) {
            console.error("Evaluation error:", err);
            setError("An error occurred during evaluation.");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (scenario_id) {
            get_and_open_scenario(scenario_id);
        }
    }, [scenario_id]);

    const boxBg = useColorModeValue("white", "gray.700");
    const responseBg = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    const totalTime = 300;
    const [timeLeft, setTimeLeft] = useState<number>(totalTime);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft]);

    return (
        <Box
            p={6}
            bg={boxBg}
            borderRadius="md"
            w={{ base: "90%", md: "80%", lg: "70%" }}
            maxW="4xl"
            mx="auto"
            mt={8}
            shadow="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            <VStack spacing={6} align="stretch">
                <HStack>
                    <Button
                        onClick={() => navigate("/answer-a-question")}
                        leftIcon={<ArrowBackIcon />}
                        variant="solid"
                        paddingX={6}
                    >
                        Back
                    </Button>
                    <Spacer/>
                    <CircularProgress
                        value={(timeLeft / totalTime) * 100}
                        size="64px"
                        thickness="6px"
                        color="red.500"
                    >
                        <CircularProgressLabel fontSize="sm">{formatTime(timeLeft)}s</CircularProgressLabel>
                    </CircularProgress>
                </HStack>
                <Box
                    p={4}
                    bg={responseBg}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={borderColor}
                >
                    <Text fontSize="2xl" fontWeight="bold" mb={2}>
                        Scenario
                    </Text>
                    <Text fontSize="md">
                        {scenario ? scenario.scenario : "Loading scenario..."}
                    </Text>
                </Box>

                {
                    scenario && (
                        <>
                            <Stepper index={activeStep} orientation="horizontal" mb={6}>
                                {scenario.questions.map((_, index) => (
                                    <Step key={index}>
                                        <StepIndicator>
                                            <StepStatus
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                                active={<StepNumber />}
                                            />
                                        </StepIndicator>

                                        <Box flexShrink="0">
                                            <StepTitle>{`Question ${index + 1}`}</StepTitle>
                                        </Box>

                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>

                            <Box>
                                <Text fontSize="xl" fontWeight="bold" mb={2}>
                                    {`Question ${activeStep + 1}`}
                                </Text>
                                <Text fontSize="md" mb={4}>
                                    {scenario.questions[activeStep]}
                                </Text>
                                <Textarea
                                    value={answers[activeStep] || ""}
                                    onChange={handleAnswerChange(activeStep)}
                                    placeholder={`Type your answer to Question ${
                                        activeStep + 1
                                    } here`}
                                    variant="filled"
                                    size="lg"
                                    resize="vertical"
                                    minH="120px"
                                    isDisabled={timeLeft <= 0}
                                />
                                <Text
                                    alignSelf="flex-end"
                                    fontSize="sm"
                                    color="gray.500"
                                    mt={1}
                                    textAlign="right"
                                >
                                    {answers[activeStep]?.length ?? 0}/3000 characters
                                </Text>
                            </Box>

                            <Box display="flex" justifyContent="space-between" mt={6}>
                                {activeStep > 0 && (
                                    <Button
                                        onClick={goToPrevious}
                                        colorScheme="gray"
                                        width="48%"
                                        size="lg"
                                        isDisabled={loading}
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    onClick={handleNext}
                                    colorScheme="blue"
                                    isLoading={loading}
                                    loadingText={
                                        activeStep === scenario.questions.length - 1
                                            ? "Submitting"
                                            : "Loading"
                                    }
                                    width={activeStep > 0 ? "48%" : "100%"}
                                    size="lg"
                                >
                                    {activeStep === scenario.questions.length - 1
                                        ? "Submit"
                                        : "Next"}
                                </Button>
                            </Box>

                            {error && (
                                <Text color="red.500" mt={2}>
                                    {error}
                                </Text>
                            )}
                        </>
                    )
                }
            </VStack>
        </Box>
    );
};

export default EvaluateCasperBoxV2;