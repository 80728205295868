import { ObjectId } from "bson";

class PlanInfo {
    name: string;
    permission_group: string;
    price: number;
    subscription_type: string;
    features: string[];
    badge_text?: string;
    button_text?: string;

    constructor(data: any) {
        this.name = data.name;
        this.permission_group = data.permission_group;
        this.price = data.price;
        this.subscription_type = data.subscription_type;
        this.features = data.features || [];
        this.badge_text = data.badge_text || undefined;
        this.button_text = data.button_text || undefined;
    }
}

class StripeInfo {
    stripe_price_id: string;

    constructor(data: any) {
        this.stripe_price_id = data.stripe_price_id;
    }
}

export class Plan {
    _id: ObjectId;
    plan_info: PlanInfo;
    stripe_info?: StripeInfo;
    archived: boolean;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.plan_info = new PlanInfo(data.plan_info);
        this.stripe_info = data.stripe_info ? new StripeInfo(data.stripe_info) : undefined;
        this.archived = data.archived;
        this.created_at = new Date(data.created_at || Date.now());
        this.updated_at = new Date(data.updated_at || Date.now());
    }
}

class SubscriptionStripeInfo {
    stripe_subscription_id: string;

    constructor(data: any) {
        this.stripe_subscription_id = data.stripe_subscription_id;
    }
}

export enum SubscriptionStatusEnum {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
    CANCELLED = 'CANCELLED',
    PENDING = 'PENDING'
}

export class Subscription {
    id: ObjectId;
    user: ObjectId;
    plan: ObjectId;
    stripe_info?: SubscriptionStripeInfo;
    status: SubscriptionStatusEnum;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this.id = new ObjectId(data.id?.$oid || data.id);
        this.user = new ObjectId(data.user?.$oid || data.user);
        this.plan = new ObjectId(data.plan?.$oid || data.plan);
        this.stripe_info = data.stripe_info ? new SubscriptionStripeInfo(data.stripe_info) : undefined;
        this.status = data.status || SubscriptionStatusEnum.ACTIVE;
        this.created_at = new Date(data.created_at || Date.now());
        this.updated_at = new Date(data.updated_at || Date.now());
    }
}

export class StripeSubscription {
    id: string;
    status: string;
    start_date: string;
    current_period_end: string;
    cancel_at_period_end: boolean;
    plan: {
        id: string;
        nickname: string;
        amount: number;
        interval: string;
        currency: string;
    };

    constructor(data: any) {
        this.id = data.id;
        this.status = data.status;
        this.start_date = data.start_date;
        this.current_period_end = data.current_period_end;
        this.cancel_at_period_end = data.cancel_at_period_end;
        this.plan = {
            id: data.plan.id,
            nickname: data.plan.nickname,
            amount: data.plan.amount,
            interval: data.plan.interval,
            currency: data.plan.currency
        };
    }
}