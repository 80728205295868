import {
    Box,
    chakra,
    Container,
    SimpleGrid,
    Stack,
    Text,
    VisuallyHidden,
    Input,
    IconButton,
    useColorModeValue,
    Image, useToast,
} from '@chakra-ui/react'
import React, {ReactNode, useState} from 'react'
import { FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa'
import { BiMailSend } from 'react-icons/bi'
import {SiTiktok} from "react-icons/all";
import {useNavigate} from "react-router-dom";
import {FormsAPI} from "../APIs/FormsAPI";

const SocialButton = ({
                          children,
                          label,
                          href,
                      }: {
    children: ReactNode
    label: string
    href: string
}) => {
    return (
        <chakra.button
            bg={useColorModeValue('secondary.50', 'secondary.800')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            target="_blank" // Opens in a new tab
            rel="noopener noreferrer" // Security measure for external links
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('primary.100', 'primary.600'),
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    )
}

export default function Footer() {

    const navigate = useNavigate();
    const toast = useToast(); // Initialize toast for notifications
    const [email, setEmail] = useState(''); // State for email input
    const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission status

    // Handler function for subscribing to the newsletter
    const handleSubscribe = async () => {
        if (!email) {
            toast({
                title: "Email is required.",
                status: "warning",
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        setIsSubmitting(true);
        try {
            const message = await FormsAPI.subscribe_to_newsletter(email);
            toast({
                title: "Subscription Successful.",
                description: message,
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            setEmail(''); // Clear the input field upon success
        } catch (error) {
            toast({
                title: "Subscription Failed.",
                description: "An error occurred.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            bg={useColorModeValue('background', 'background')}
            color={useColorModeValue('text', 'text')}>
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 2fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box>
                            <Image
                                src="/logo1.svg"
                                mb={8}
                                boxSize="60px"
                                mx="auto"
                                borderRadius="full"
                            />
                        </Box>
                        <Text fontSize={'sm'}>© 2024 CasPrep. All rights reserved</Text>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Company</ListHeader>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    About us*/}
                        {/*</Box>*/}
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Blog*/}
                        {/*</Box>*/}
                        <Box as="a" href={'/contact-us'} target="_blank" rel="noopener noreferrer">
                            Contact us
                        </Box>
                        <Box as="a" href={'/pricing'} target="_blank" rel="noopener noreferrer">
                            Pricing
                        </Box>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Testimonials*/}
                        {/*</Box>*/}
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Support</ListHeader>
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Help Center*/}
                        {/*</Box>*/}
                        {/*<Box as="a" href={'#'}>*/}
                        {/*    Terms of Service*/}
                        {/*</Box>*/}
                        <Box as="a" href={'/legal'} target="_blank" rel="noopener noreferrer">
                            Legal Policy
                        </Box>
                        <Box as="a" href={'/privacy'} target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </Box>
                        <Box as="a" href={'/status'}>
                            Status
                        </Box>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Stay up to date</ListHeader>
                        <Stack direction={'row'}>
                            <Input
                                placeholder={'Your email address'}
                                value={email} // Bind input value to state
                                onChange={(e) => setEmail(e.target.value)} // Update state on change
                                bg={useColorModeValue('secondary.50', 'secondary.800')}
                                border={0}
                                _focus={{
                                    bg: useColorModeValue('primary.100', 'primary.600'),
                                }}
                            />
                            <IconButton
                                bg={useColorModeValue('primary.500', 'primary.700')}
                                color={useColorModeValue('text', 'white')}
                                _hover={{
                                    bg: useColorModeValue('primary.600', 'primary.800'),
                                }}
                                aria-label="Subscribe"
                                icon={<BiMailSend />}
                                onClick={handleSubscribe} // Attach handler function
                                isLoading={isSubmitting} // Show loading state
                                disabled={isSubmitting} // Disable button while submitting
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={6}>
                            <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/company/casprep/'}>
                                <FaLinkedin />
                            </SocialButton>
                            <SocialButton label={'TikTok'} href={'https://www.tiktok.com/@casprep.net'}>
                                <SiTiktok />
                            </SocialButton>
                            <SocialButton label={'Instagram'} href={'https://www.instagram.com/casprepdotnet/'}>
                                <FaInstagram />
                            </SocialButton>
                        </Stack>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    )
}