    import React, {useEffect, useRef, useState} from 'react';
    import {
    Box,
    Grid,
    Text,
    OrderedList,
    ListItem,
    Divider,
    Badge,
    Flex,
    Heading,
    useColorModeValue,
    GridItem, Center, Spacer
    } from '@chakra-ui/react';
    import { Chart, registerables } from 'chart.js';
    import {useAuth} from "../utils/firebase/authContext";
    import {formatDistanceToNow, isPast} from "date-fns";
    import {CasperAPI} from "../APIs/CasperAPI";
    import CasperDashboardData = CasperAPI.CasperDashboardData;

    Chart.register(...registerables);

    // Fake data for charts and lists
    // const dashboard_data = {
    //     questionsCompleted: {
    //         title: "Questions Completed",
    //         labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    //         data: [10, 20, 15, 25, 30]
    //     },
    //     averageQuartileScore: {
    //         title: "Average Quartile Score",
    //         labels: ["Jan", "Feb", "Mar", "Apr", "May"],
    //         data: [1.2, 1.5, 1.3, 1.7, 1.6]
    //     },
    //     customGraph: {
    //         title: "Words Per Minute (WPM)",
    //         labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"], 
    //         data: [40, 50, 45, 55, 60] 
    //     },
    //     listData: {
    //         topMissedCharacteristics: {
    //             title: "Top Missed Characteristics",
    //             data: [
    //                 { label: "Empathy", value: "18%", trend: "down" },
    //                 { label: "Critical Thinking", value: "15%", trend: "down" },
    //                 { label: "Communication", value: "20%", trend: "down" },
    //                 { label: "Ethical Reasoning", value: "12%", trend: "down" },
    //                 { label: "Professionalism", value: "10%", trend: "down" }
    //             ]
    //         },
    //         topHitOnCharacteristics: {
    //             title: "Top Hit-On Characteristics",
    //             data: [
    //                 { label: "Leadership", value: "22%", trend: "up" },
    //                 { label: "Teamwork", value: "25%", trend: "up" },
    //                 { label: "Problem Solving", value: "18%", trend: "up" },
    //                 { label: "Adaptability", value: "20%", trend: "up" },
    //                 { label: "Decision Making", value: "19%", trend: "up" }
    //             ]
    //         },
    //         lastAnsweredQuestions: {
    //             title: "Last Answered Questions",
    //             data: [
    //                 "How would you handle a conflict with a colleague?",
    //                 "Describe a time you demonstrated leadership.",
    //                 "What is the most challenging ethical dilemma you've faced?",
    //                 "How do you prioritize tasks under pressure?",
    //                 "What is your approach to teamwork in a diverse environment?"
    //             ]
    //         }
    //     }
    // };

    const LineGraph: React.FC<{ id: string; title: string; data: { labels: string[], data: number[] } }> = ({ id, title, data }) => {
        const chartRef = useRef<Chart | null>(null);
        const lineColor = useColorModeValue('rgba(72, 170, 188, 1)', 'rgba(115, 53, 241, 1)');
        const gradientColor = useColorModeValue('rgba(72, 170, 188, ', 'rgba(115, 53, 241, ');

        // Create the gradient function for cleaner useEffect code
        const createGradient = (ctx: CanvasRenderingContext2D) => {
            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, `${gradientColor}0.5)`);
            gradient.addColorStop(0.35, `${gradientColor}0.25)`);
            gradient.addColorStop(1, `${gradientColor}0)`);
            return gradient;
        };

        useEffect(() => {
            const canvas = document.getElementById(id) as HTMLCanvasElement | null;
            if (!canvas) return;

            const ctx = canvas.getContext('2d');
            if (!ctx) return;

            const gradient = createGradient(ctx);

            // Destroy the existing chart instance if it exists
            if (chartRef.current) {
                chartRef.current.destroy();
            }

            // Create a new chart instance
            chartRef.current = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: data.labels,
                    datasets: [{
                        label: title,
                        data: data.data,
                        fill: true,
                        backgroundColor: gradient,
                        pointBackgroundColor: lineColor,
                        borderColor: lineColor,
                        tension: 0.4,
                    }]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            grid: {
                                color: 'rgba(80, 102, 120, 0.25)',
                            },
                            suggestedMax: Math.max(...data.data) * 1.1 // Adds 10% space above the top value
                        },
                        x: {
                            grid: {
                                display: false,
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: false,
                        }
                    }
                },
            });

            // Cleanup on component unmount
            return () => {
                if (chartRef.current) {
                    chartRef.current.destroy();
                }
            };
        }, [id, lineColor, gradientColor, data, title]);

        return (
            <Box
                width="100%"
                height={["300px", "350px", "400px"]}
                position="relative"
                className="chart-container"
                bg={useColorModeValue('white', 'gray.800')}
                borderRadius="lg"
                boxShadow="md"
                p={4}
            >
                <Heading as="h3" size="md" mb={4}>{title}</Heading>
                <Box height="calc(100% - 32px)" width="100%">
                    <canvas id={id}></canvas>
                </Box>
            </Box>
        );
    };

    const ListItems: React.FC<{ title: string; data: { label: string; value: string; trend?: string }[] }> = ({ title, data }) => {
        const getBadgeColor = (trend: string) => {
            if (trend === 'up') return 'green';
            if (trend === 'down') return 'red';
            return 'yellow'; // Neutral option (yellow)
        };

        const getTrendIcon = (trend: string) => {
            if (trend === 'up') return '▲';
            if (trend === 'down') return '▼';
            return '●'; // Neutral icon
        };

        return (
            <Box
                bg={useColorModeValue('white', 'gray.800')}
                borderRadius="lg"
                boxShadow="md"
                p={4}
                height="100%"
                display="flex"
                flexDirection="column"
            >
                <Heading as="h3" size="md" mb={4}>{title}</Heading>
                <OrderedList spacing={4} ml={2} flex="1">
                    {data.map((item, i) => (
                        <React.Fragment key={i}>
                            <ListItem fontSize="md" pl={2} display="flex" justifyContent="space-between" alignItems="center">
                                <Text fontWeight="medium">{item.label}</Text>
                                <Flex align="center">
                                    <Text fontWeight="bold" fontSize="sm" mr={2}>{item.value}</Text>
                                    {item.trend && (
                                        <Badge colorScheme={getBadgeColor(item.trend)} ml={2}>
                                            {getTrendIcon(item.trend)}
                                        </Badge>
                                    )}
                                </Flex>
                            </ListItem>
                            {i < data.length - 1 && <Divider mt={3} />}
                        </React.Fragment>
                    ))}
                </OrderedList>
            </Box>
        );
    };
    
    const Dashboard: React.FC = () => {
        const user = useAuth().user;
        const [time_left, set_time_left] = useState<string | undefined>(undefined);
        const [dashboard_data, set_dashboard_data] = useState<CasperDashboardData | undefined>(undefined);

        const getDashboard = async () => {
            try {
                const dashboard = await CasperAPI.get_casper_dashboard();
                set_dashboard_data(dashboard);
            } catch (error) {
            }
        };

        useEffect(() => {
            getDashboard().then();
        }, []);

        useEffect(() => {
            if (!user?.exam_date || isPast(new Date(user.exam_date))) {
                set_time_left(undefined);
                return;
            }

            const examDate = new Date(user.exam_date);
            const distance = formatDistanceToNow(examDate, { addSuffix: false });

            set_time_left(`${distance} left until Casper exam!`);
        }, [user]);

        return (
            <Box p={[4, 6, 8]}>
                <Heading as="h1" size="xl" mb={6}>Welcome {user?.full_name} </Heading>
                <Heading as="h1" size="xl" textAlign="center" mb={2}>Dashboard Overview</Heading>
                {
                    time_left ?
                        <Center>
                            <Badge  textAlign="center" size={"md"}>
                                {time_left}
                            </Badge>
                        </Center>
                        :
                        <>
                        </>
                }
                <Spacer h={"25px"}/>
                <Grid
                    templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                    gap={6}
                >
                    {
                        dashboard_data ?
                            <>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <LineGraph id="chart1" title={dashboard_data.questionsCompleted.title} data={dashboard_data.questionsCompleted} />
                                </GridItem>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <LineGraph id="chart2" title={dashboard_data.averageQuartileScore.title} data={dashboard_data.averageQuartileScore} />
                                </GridItem>
                                <GridItem colSpan={[1, 2, 1]}>
                                    <LineGraph id="chart3" title={dashboard_data.customGraph.title} data={dashboard_data.customGraph} />
                                </GridItem>
                            </>
                            :
                            <>
                            </>
                    }
                </Grid>
                <Box height={['5px', '55px', '105px']}  />
                <Grid
                    templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                    gap={6}
                >
                    {
                        dashboard_data ?
                            <>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <ListItems title={dashboard_data.listData.topMissedCharacteristics.title} data={dashboard_data.listData.topMissedCharacteristics.data} />
                                </GridItem>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <ListItems title={dashboard_data.listData.topHitOnCharacteristics.title} data={dashboard_data.listData.topHitOnCharacteristics.data} />
                                </GridItem>
                                <GridItem colSpan={[1, 2, 1]}>
                                    <ListItems title={dashboard_data.listData.lastAnsweredQuestions.title} data={dashboard_data.listData.lastAnsweredQuestions.data.map(question => ({ label: question, value: '', trend: '' }))} />
                                </GridItem>
                            </>
                            :
                            <>
                            </>
                    }
                </Grid>
            </Box>
        );
    };

    export default Dashboard;