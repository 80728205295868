// src/screens/ProfileScreen.tsx

import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import ProfileComponent from "../../components/ProfileComponent";

const ProfileScreen: React.FC = () => {
    return (
        <Box
            maxW="600px"
            mx="auto"
            p={6}
            bg="background"
            color="text"
            borderRadius="md"
            boxShadow="md"
        >
            <Heading as="h2" mb={4} color="text">
                User Profile
            </Heading>
            <ProfileComponent />
        </Box>
    );
};

export default ProfileScreen;