import {
    Box,
    Stack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    Tabs, TabList, TabPanels, Tab, TabPanel, Badge, Button, Center
} from '@chakra-ui/react';
import { Plan } from "../../models/Payment";
import { PaymentsAPI } from "../../APIs/PaymentsAPI";
import React, { useEffect, useState } from "react";
import {PlanCard} from "./PlanCard";
import {useNavigate} from "react-router-dom";

export const PricingPage = () => {

    const navigate = useNavigate();

    const borderColor = useColorModeValue('gray.200', 'gray.500');
    const bgColor = useColorModeValue('gray.50', 'gray.700');
    const badgeBgColor = useColorModeValue('red.300', 'red.700');
    const badgeTextColor = useColorModeValue('gray.900', 'gray.300');

    const tabBg = useColorModeValue('gray.100', 'gray.700');
    const selectedTabBg = useColorModeValue('white', 'gray.800');

    const [plans, setPlans] = useState<Plan[]>([]);

    useEffect(() => {
        const fetchPlans = async () => {
            try {
                const fetchedPlans = await PaymentsAPI.get_self_plans();
                if (fetchedPlans) setPlans(fetchedPlans.sort((a, b) => a.plan_info.price - b.plan_info.price));
            } catch (error) {
                console.error('Error fetching plans:', error);
            }
        };

        fetchPlans().then();
    }, []);

    const renderPlans = (subscriptionType: string) => (
        <Stack
            direction={{ base: 'column', md: 'row' }}
            textAlign="center"
            justify="center"
            spacing={{ base: 4, lg: 10 }}
            py={10}
        >
            {plans.filter(plan => plan.plan_info.subscription_type === subscriptionType && !plan.archived || plan.plan_info.subscription_type === "FREE" && !plan.archived).map(plan => (
                <PlanCard
                    key={plan.plan_info.name}
                    plan={plan}
                    borderColor={borderColor}
                    bgColor={bgColor}
                    badgeBgColor={badgeBgColor}
                    badgeTextColor={badgeTextColor}
                />
            ))}
        </Stack>
    );

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    Plans that fit your need
                </Heading>
                <Text fontSize="lg" color="gray.500">
                    Start with the basic plan for free. No credit card needed. Cancel at any time.
                </Text>
            </VStack>

            <Tabs
                align="center"
                variant="soft-rounded"
                colorScheme="blue"
                mt={8}
                mb={12}>
                <TabList
                    bg={tabBg}
                    borderRadius="full"
                    minW={"300px"}
                    maxW={"400px"}
                    p={2}
                    gap={6}
                >
                    <Tab
                        _selected={{
                            bg: selectedTabBg,
                            color: 'red.500',
                            fontWeight: 'bold',
                            boxShadow: 'md',
                        }}
                        borderRadius="full"
                        px={6}
                    >
                        1 Week
                    </Tab>
                    <Tab
                        _selected={{
                            bg: selectedTabBg,
                            color: 'red.500',
                            fontWeight: 'bold',
                            boxShadow: 'md',
                        }}
                        borderRadius="full"
                        px={6}
                        position="relative"
                    >
                        1 Month
                        <Badge
                            ml={2}
                            colorScheme="red"
                            borderRadius="full"
                            px={2}
                            py={1}
                            fontSize="0.7em"
                            position="absolute"
                            right="-20px"
                            top="-10px"
                        >
                            SAVE 50%
                        </Badge>
                    </Tab>
                    {/*<Tab*/}
                    {/*    _selected={{*/}
                    {/*        bg: selectedTabBg,*/}
                    {/*        color: 'red.500',*/}
                    {/*        fontWeight: 'bold',*/}
                    {/*        boxShadow: 'md',*/}
                    {/*    }}*/}
                    {/*    borderRadius="full"*/}
                    {/*    px={6}*/}
                    {/*    position="relative"*/}
                    {/*>*/}
                    {/*    1 Year*/}
                    {/*    <Badge*/}
                    {/*        ml={2}*/}
                    {/*        colorScheme="red"*/}
                    {/*        borderRadius="full"*/}
                    {/*        px={2}*/}
                    {/*        py={1}*/}
                    {/*        fontSize="0.7em"*/}
                    {/*        position="absolute"*/}
                    {/*        right="-20px"*/}
                    {/*        top="-10px"*/}
                    {/*    >*/}
                    {/*        SAVE 75%*/}
                    {/*    </Badge>*/}
                    {/*</Tab>*/}
                </TabList>

                <TabPanels>
                    <TabPanel>{renderPlans("WEEKLY")}</TabPanel>
                    <TabPanel>{renderPlans("MONTHLY")}</TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
}