import React, { useEffect, useState } from 'react';
import {
    Box,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Select,
    Text,
    Flex,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    SimpleGrid,
    Card,
    CardBody,
    CardHeader,
    Stack,
    useColorModeValue, Th, Td, Table, Thead, TableContainer, Tbody, Tr,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FormSubmission, FormSubmissionStatusEnum } from '../../models/Models';
import { FormsAPI } from '../../APIs/FormsAPI';

const FormSubmissionsPage: React.FC = () => {
    const [formSubmissions, setFormSubmissions] = useState<FormSubmission[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [updatingStatusIds, setUpdatingStatusIds] = useState<Set<string>>(new Set());

    const limit = 10; // Number of submissions per page

    // Fetch form submissions on component mount
    useEffect(() => {
        const fetchFormSubmissions = async () => {
            try {
                setLoading(true);
                const submissions = await FormsAPI.get_all_form_submissions();
                setFormSubmissions(submissions);
                setTotalPages(Math.ceil(submissions.length / limit));
                setLoading(false);
            } catch (err: any) {
                setError('Failed to load form submissions: ' + err.message);
                setLoading(false);
            }
        };

        fetchFormSubmissions();
    }, []);

    // Compute the submissions to display on the current page
    const indexOfLastSubmission = currentPage * limit;
    const indexOfFirstSubmission = indexOfLastSubmission - limit;
    const currentSubmissions = formSubmissions.slice(indexOfFirstSubmission, indexOfLastSubmission);

    // Filter submissions based on status
    const pendingOrInReviewSubmissions = formSubmissions.filter(
        (submission) =>
            submission.status === FormSubmissionStatusEnum.Pending ||
            submission.status === FormSubmissionStatusEnum.Reviewed
    );
    const resolvedSubmissions = formSubmissions.filter(
        (submission) => submission.status === FormSubmissionStatusEnum.Resolved
    );

    // Handle status change
    const handleStatusChange = async (submissionId: string, newStatus: FormSubmissionStatusEnum) => {
        try {
            // Indicate which submission is being updated
            setUpdatingStatusIds((prev) => new Set(prev).add(submissionId));
            await FormsAPI.update_form_status(submissionId, newStatus);
            // Update the local state with the new status
            setFormSubmissions((prevSubmissions) =>
                prevSubmissions.map((submission) =>
                    submission._id.toString() === submissionId
                        ? { ...submission, status: newStatus, updated_at: new Date() }
                        : submission
                )
            );
        } catch (err: any) {
            setError('Failed to update status: ' + err.message);
        } finally {
            setUpdatingStatusIds((prev) => {
                const newSet = new Set(prev);
                newSet.delete(submissionId);
                return newSet;
            });
        }
    };

    // Handle page navigation
    const goToPreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    // Get color mode value outside of callback
    const cardBg = useColorModeValue('gray.100', 'gray.700');

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert status="error" mb={4}>
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    return (
        <Box p={4}>
            <Text fontSize="2xl" mb={4}>
                Form Submissions
            </Text>
            <Tabs variant="enclosed">
                <TabList>
                    <Tab>Pending & In Review</Tab>
                    <Tab>Resolved</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        {/* Pending & In Review Submissions in Card View */}
                        <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                            {pendingOrInReviewSubmissions.map((submission) => (
                                <Card
                                    key={submission._id.toString()}
                                    borderWidth="1px"
                                    borderRadius="lg"
                                    overflow="hidden"
                                    bg={cardBg}  // Use color mode value here
                                >
                                    <CardHeader>
                                        <Text fontSize="lg" fontWeight="bold">
                                            {submission.name}
                                        </Text>
                                        <Text fontSize="sm" color="gray.500">
                                            {submission.email}
                                        </Text>
                                    </CardHeader>
                                    <CardBody>
                                        <Stack spacing={3}>
                                            <Text>{submission.message}</Text>
                                            <Select
                                                value={submission.status}
                                                onChange={(e) =>
                                                    handleStatusChange(
                                                        submission._id.toString(),
                                                        e.target.value as FormSubmissionStatusEnum
                                                    )
                                                }
                                                isDisabled={updatingStatusIds.has(
                                                    submission._id.toString()
                                                )}
                                            >
                                                {Object.values(FormSubmissionStatusEnum).map(
                                                    (status) => (
                                                        <option key={status} value={status}>
                                                            {status}
                                                        </option>
                                                    )
                                                )}
                                            </Select>
                                            {updatingStatusIds.has(submission._id.toString()) && (
                                                <Spinner size="sm" />
                                            )}
                                            <Text fontSize="xs" color="gray.500">
                                                Submitted: {new Date(submission.created_at).toLocaleString()}
                                            </Text>
                                            <Text fontSize="xs" color="gray.500">
                                                Updated: {new Date(submission.updated_at).toLocaleString()}
                                            </Text>
                                        </Stack>
                                    </CardBody>
                                </Card>
                            ))}
                        </SimpleGrid>
                    </TabPanel>

                    <TabPanel>
                        {/* Resolved Submissions in Table View */}
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>ID</Th>
                                        <Th>Name</Th>
                                        <Th>Email</Th>
                                        <Th>Message</Th>
                                        <Th>Status</Th>
                                        <Th>Created At</Th>
                                        <Th>Updated At</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {resolvedSubmissions.map((submission) => (
                                        <Tr key={submission._id.toString()}>
                                            <Td>{submission._id.toString()}</Td>
                                            <Td>{submission.name}</Td>
                                            <Td>{submission.email}</Td>
                                            <Td>{submission.message}</Td>
                                            <Td>{submission.status}</Td>
                                            <Td>{new Date(submission.created_at).toLocaleString()}</Td>
                                            <Td>{new Date(submission.updated_at).toLocaleString()}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* Pagination Controls */}
            <Flex justifyContent="space-between" alignItems="center" mt={4}>
                <Button
                    leftIcon={<ChevronLeftIcon />}
                    onClick={goToPreviousPage}
                    isDisabled={currentPage === 1}
                >
                    Previous
                </Button>
                <Text>
                    Page {currentPage} of {totalPages}
                </Text>
                <Button
                    rightIcon={<ChevronRightIcon />}
                    onClick={goToNextPage}
                    isDisabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </Flex>
        </Box>
    );
};

export default FormSubmissionsPage;