import {
    Badge,
    Box,
    Button,
    Center,
    Heading,
    Progress,
    Stack,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Plan, StripeSubscription } from "../../models/Payment";
import { PaymentsAPI } from "../../APIs/PaymentsAPI";
import { PlanCard } from "./PlanCard";
import { useNavigate } from "react-router-dom";
import { StripeSubscriptionCard } from "./StripeSubscriptionCard";

export const PlanPage = () => {
    const navigate = useNavigate();

    const borderColor = useColorModeValue('gray.200', 'gray.500');
    const bgColor = useColorModeValue('gray.50', 'gray.700');
    const badgeBgColor = useColorModeValue('red.300', 'red.700');
    const badgeTextColor = useColorModeValue('gray.900', 'gray.300');

    // State for the current plan
    const [currentPlan, setCurrentPlan] = useState<Plan | null>(null);
    const [isPlanLoading, setIsPlanLoading] = useState<boolean>(true);

    // State for the Stripe subscription
    const [currentPlanStripeSubscription, setCurrentPlanStripeSubscription] = useState<StripeSubscription | undefined>(undefined);
    const [isStripeLoading, setIsStripeLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchCurrentPlan = async () => {
            try {
                setIsPlanLoading(true);
                const res = await PaymentsAPI.get_current_plan(1);
                if (res) {
                    setCurrentPlan(res.plan);
                    // Handle optional stripe_subscription
                    if (res.stripe_subscription) {
                        setCurrentPlanStripeSubscription(res.stripe_subscription);
                    } else {
                        setCurrentPlanStripeSubscription(undefined);
                    }
                }
            } catch (error) {
                console.error('Error fetching current plan:', error);
            } finally {
                setIsPlanLoading(false);
                setIsStripeLoading(false);
            }
        };

        fetchCurrentPlan().then();
    }, []);

    return (
        <Box py={12}>
            <VStack spacing={2} textAlign="center">
                <Heading as="h1" fontSize="4xl">
                    My Plan
                </Heading>
                <Text fontSize="lg" color="gray.500">
                    Here is your current subscription plan.
                </Text>
            </VStack>

            <Stack
                direction={{ base: 'column', md: 'row' }}
                textAlign="center"
                justify="center"
                spacing={{ base: 4, lg: 10 }}
                py={10}
            >
                {/* Plan Card Section */}
                {isPlanLoading ? (
                    <Progress
                        hasStripe
                        isIndeterminate
                        size="lg"
                        colorScheme="blue" // Changed color for differentiation
                        width="100%"
                    />
                ) : currentPlan ? (
                    <PlanCard
                        key={currentPlan.plan_info.name}
                        plan={currentPlan}
                        borderColor={borderColor}
                        bgColor={bgColor}
                        badgeBgColor={badgeBgColor}
                        badgeTextColor={badgeTextColor}
                    />
                ) : (
                    <Box>
                        <Text>No active plan found.</Text>
                        <Button
                            mt={4}
                            colorScheme="teal"
                            onClick={() => navigate('/pricing')}
                        >
                            Choose a Plan
                        </Button>
                    </Box>
                )}

                {/* Stripe Subscription Section */}
                {isStripeLoading ? (
                    <Progress
                        hasStripe
                        isIndeterminate
                        size="lg"
                        colorScheme="red" // Red color for Stripe subscription
                        width="100%"
                    />
                ) : currentPlanStripeSubscription ? (
                    <StripeSubscriptionCard
                        key={currentPlanStripeSubscription.id}
                        borderColor={borderColor}
                        bgColor={bgColor}
                        subscription={currentPlanStripeSubscription}
                    />
                ) : (
                    <>

                    </>
                )}
            </Stack>

            <Center>
                <Button
                    w="200px"
                    backgroundColor="red.500"
                    color="white"
                    variant="solid"
                    onClick={() => {
                        navigate('/pricing');
                    }}
                >
                    Go to Pricing Page
                </Button>
            </Center>
        </Box>
    );
};