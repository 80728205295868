import React, { useState } from 'react';
import { Box, Button, Input, useToast, Stack, Text } from '@chakra-ui/react';
import { CasperAPI } from '../../APIs/CasperAPI';

const BulkUploadScenario: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const toast = useToast();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files ? event.target.files[0] : null;
        setFile(selectedFile);
    };

    const handleUpload = async () => {
        if (!file) {
            toast({
                title: "No File Selected",
                description: "Please select a .csv file to upload.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        try {
            await CasperAPI.bulk_upload_scenarios(formData);  // Correct usage of FormData
            toast({
                title: "Upload Successful",
                description: "The CSV file has been successfully uploaded.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Upload Failed",
                description: "There was an error uploading the file. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box>
            <Stack spacing={4}>
                <Text>Upload a .csv file to bulk upload scenarios.</Text>
                <Input type="file" accept=".csv" onChange={handleFileChange} />
                <Button colorScheme="blue" onClick={handleUpload}>
                    Upload
                </Button>
            </Stack>
        </Box>
    );
};

export default BulkUploadScenario;