import {
    Box,
    Stack,
    HStack,
    Heading,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Badge,
    useDisclosure,
    AlertDialog,
    AlertDialogBody,
    AlertDialogOverlay,
    AlertDialogFooter, AlertDialogContent, AlertDialogHeader
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { Plan } from "../../models/Payment";
import { PaymentsAPI } from "../../APIs/PaymentsAPI";
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

export interface PlanCardProps {
    plan: Plan;
    borderColor: string;
    bgColor: string;
    badgeBgColor: string;
    badgeTextColor: string;
}

export const PlanCard: React.FC<PlanCardProps> = ({ plan, borderColor, bgColor, badgeBgColor, badgeTextColor }) => {

    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef<HTMLButtonElement>(null);
    const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const change_plan = async (plan: Plan) => {
        try {
            const checkout_url = await PaymentsAPI.change_plan(plan._id);
            if (checkout_url) {
                window.location.href = checkout_url;
                return;
            }
            navigate("/my-plan");

        } catch (error) {
            console.error('Error changing plan:', error);
        } finally {
            setIsLoading(false);
            setSelectedPlan(null);
        }
    };

    const handleButtonClick = (plan: Plan) => {
        if (plan.plan_info && plan.plan_info.button_text && plan.plan_info.button_text.includes('Downgrade')) {
            setSelectedPlan(plan);
            onOpen();
        } else {
            setIsLoading(true);
            change_plan(plan).then();
        }
    };

    const handleConfirm = () => {
        if (selectedPlan) {
            setIsLoading(true);
            change_plan(selectedPlan).then();
        }
        onClose();
    };

    return (
        <Box mb={4} shadow="base" borderWidth="1px" borderColor={borderColor} borderRadius="xl" alignSelf={{ base: 'center', lg: 'flex-start' }}>
            <Box position="relative">
                {plan.plan_info.badge_text && (
                    <Box position="absolute" top="-16px" left="50%" style={{ transform: 'translate(-50%)' }}>
                        <Text
                            textTransform="uppercase"
                            bg={badgeBgColor}
                            px={3}
                            py={1}
                            color={badgeTextColor}
                            fontSize="sm"
                            fontWeight="600"
                            rounded="xl"
                        >
                            {plan.plan_info.badge_text}
                        </Text>
                    </Box>
                )}
                <Box py={4} px={12}>
                    <Text fontWeight="500" fontSize="2xl">
                        {plan.plan_info.name}
                    </Text>
                    <HStack justifyContent="center">
                        <Text fontSize="3xl" fontWeight="600">$</Text>
                        <Text fontSize="5xl" fontWeight="900">{plan.plan_info.price}</Text>
                        <Text fontSize="3xl" color="gray.500">
                            {plan.plan_info.subscription_type === "WEEKLY" ? "/week" :
                                plan.plan_info.subscription_type === "MONTHLY" ? "/month" :
                                    plan.plan_info.subscription_type === "YEARLY" ? "/year" : ""}
                        </Text>
                    </HStack>
                </Box>
                <VStack bg={bgColor} py={4} borderBottomRadius="xl">
                    <List spacing={3} textAlign="start" px={12}>
                        {plan.plan_info.features.map((point, index) => (
                            <ListItem key={index}>
                                <ListIcon as={FaCheckCircle} color="red.500" />
                                {point}
                            </ListItem>
                        ))}
                    </List>
                    <Box w="80%" pt={7}>
                        <Button
                            w="full"
                            colorScheme={
                                plan.plan_info.button_text === "Current Plan" ? 'gray' :
                                    plan.plan_info.button_text === "Upgrade Plan" ? 'green' :
                                        'red' // Default for Downgrade Plan
                            }
                            variant={'solid'}
                            onClick={() => handleButtonClick(plan)}
                            isDisabled={plan.plan_info.button_text === "Current Plan"} // Disable button for current plan
                            isLoading={isLoading}
                        >
                            {plan.plan_info.button_text || 'Get Started'}
                        </Button>
                    </Box>
                </VStack>
            </Box>

            {/* Confirmation AlertDialog */}
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Confirm Downgrade
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            You will lose access to premium features at the end of this current billing cycle. Are you sure you want to downgrade your plan?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                                Downgrade
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Box>
    )
};